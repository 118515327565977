import { fork, all } from 'redux-saga/effects';
import searchSaga from './searchSaga';
import chooseSaga from './chooseSaga';
import timelineSaga from './timelineSaga';
import addressSaga from './addressSaga';
import authSaga from './authSaga';

export default function* rootSaga() {
  yield all([
    fork(searchSaga),
    fork(chooseSaga),
    fork(timelineSaga),
    fork(addressSaga),
    fork(authSaga)
  ]);
}