import { useState } from 'react';
import RPlayer from 'react-player';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './reactPlayer.scss';

const ReactPlayer = ({ videoSrc, showSpin = false, ...props }) => {
    const [removeSpin, setRemoveSpin] = useState(false);

    const videoLoad = () => {
        setRemoveSpin(true);
    }

    return <div style={{ position: 'relative' }}>
        {showSpin && !removeSpin && <div className="spin-wrap"><Spin size="large" indicator={<LoadingOutlined spin />} /></div>}
        {videoSrc && <RPlayer url={videoSrc} onReady={videoLoad} {...props} />}
    </div>
}

export default ReactPlayer;