import { TestCard, CloseButton, LabelText, Title } from '@components';
import { ModalLayout } from '@layouts';
import { signUpSvg } from '@icons';
import './infoPopup.scss';

const InfoPopup = ({ openModal, onClose, serial_number, test_type, birthdayShortStr, formatDateShort, formatTime, fullName, gender, passport_number }) => {
    return <ModalLayout isOpen={openModal} style={{ width: '535px', height: '395px' }}>
        <div className="info-popup-wrap">
            <CloseButton onClick={onClose} />

            <TestCard title="Lateral Flow Test" short_description="some desc" size="medium" />

            <div style={{ display: 'flex' }}>
                <div className="info-popup-rowone">
                    <LabelText label="test id" text={serial_number} />
                    <LabelText label="type" text={test_type} style={{ maxWidth: '200px' }} />
                    <LabelText label="activation date" text={`${formatDateShort} ${formatTime}`} />
                </div>
            </div>

            <Title title="Who is tested" style={{ fontWeight: 700, marginTop: '30px', marginBottom: '24px' }} />

            <div className="info-popup-rowtwo">
                <img className="avatar" src={signUpSvg} />
                <LabelText label="name" text={fullName} style={{maxWidth: '95px'}}/>
                <LabelText label="sex" text={gender} />
                <LabelText label="birthday" text={birthdayShortStr} />
                <LabelText label="personal id" text={passport_number} />
            </div>

        </div>
    </ModalLayout >

}

export default InfoPopup;