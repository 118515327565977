import { antigenImg, pcrImg, pcrBlueImg, switchHealthImg } from '@assets/img/tests';

export const testImg = {
    antigen: antigenImg,
    pcrBlueTest: pcrBlueImg,
    switchHealthTest: switchHealthImg,
    pcr: pcrImg,
    pcr_day_2_day_8: pcrImg,
    pcr_day_5: pcrImg,
    antigen_fit_to_fly: antigenImg,
    pcr_fit_to_fly: pcrImg,
    default: antigenImg
}