import { useEffect } from 'react';
import { DesktopOrTabletBreakpoint, MobileBreakpoint } from '@components';
import './modalLayout.scss';

//animateType: default (opacity) | moveLeft (from -563px, for auth popup)
const ModalLayout = ({ children, isOpen, animateType = "default", ...props }) => {
    useEffect(() => {
        document.body.classList.toggle('modal-open', isOpen);
    }, [isOpen]);

    if (isOpen) return <>
        <DesktopOrTabletBreakpoint>
            <div className="modal-mask"></div>
            <div className={`modal-wrap ${animateType === "moveLeft" ? 'animation-move-left' : 'animation-default'}`} {...props}>{children}</div>
        </DesktopOrTabletBreakpoint>

        <MobileBreakpoint>
            <div className="mobile-modal-wrap" {...props}>{children}</div>
        </MobileBreakpoint>
    </>

    return null;
}

export default ModalLayout;