import './mtcLayout.scss';

const MTCLayout = ({ colOne, colTwo }) => {
    return <div className="mtc-layout-wrap">

        <div className="mtc-col-one">{colOne}</div>

        <div className="mtc-col-two">{colTwo}</div>

    </div>
}

export default MTCLayout;