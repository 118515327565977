import { call, put, takeLatest } from 'redux-saga/effects';
import { searchFetch, searchFetchSuccess, searchFetchFail } from '@slice/searchSlice';
import { searchTest } from '@utils/lyfe-api';

function* searchSaga({ payload }) {
    try {
        const response = yield call(searchTest, payload);
        const { success, data } = response;

        window.Bugfender.log(`[/tests/search] params: ${JSON.stringify(payload)}; response: ${JSON.stringify(response)}`);

        if (success) {
            yield put(searchFetchSuccess(data));
        } else {
            yield put(searchFetchFail());
        }
    } catch (exception) {
        yield put(searchFetchFail());
    }
}

export default function* () {
    yield takeLatest(searchFetch, searchSaga);
}