import { Radio, Col, Tooltip } from 'antd';
import { TestCard } from '@components';
import { antigenImg, pcrImg } from '@assets/img/tests';
import { checkedSvg, uncheckSvg, virusSvg } from '@icons';

import './testRadioCard.scss';

const imgAlias = {
    antigen: antigenImg,
    pcr: pcrImg,
    pcr_day_2_day_8: pcrImg,
    pcr_day_5: pcrImg,
    antigen_fit_to_fly: antigenImg,
    pcr_fit_to_fly: pcrImg
}

const TestRadioCard = ({ id, name, short_description, price, category, isDisableChoose, isEmpty, isChecked }) => {
    if (isEmpty) return <Radio.Button className={`radio-card-wrapper`} disabled>
        <Col>
            <img className="test-logo" src={virusSvg} />
        </Col>
        <Col>
            <div className="title-suitable-test">Not found suitable tests</div>
            <div className="description">Please try different one</div>
        </Col>
    </Radio.Button>

    const RadioButton = <Radio.Button value={id} className={`radio-card-wrapper`} disabled={isDisableChoose}>
        <TestCard category={category} title={name} short_description={short_description} size="small" />
        
        <img className="check-status" src={isChecked ? checkedSvg : uncheckSvg} />
    </Radio.Button>;

    if (isDisableChoose) { // wrap disable card with tooltip, for show message
        return <Tooltip title="To proceed with this test product, please select date and time">
            {RadioButton}
        </Tooltip>
    }

    return RadioButton;
}

export default TestRadioCard;