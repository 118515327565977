import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from "react-router-dom";
import { setRedirectInfo, resetRedirectInfo } from "@slice/redirectSlice";
import { addressFetch, addressDetailFetch } from "@slice/addressSlice";
import {
    Title, CloseButton, Button, DesktopOrTabletBreakpoint, MobileBreakpoint, FixedButton, InputNumber, Slider, Input, Autocomplete, Description, TestCard, OrderSummary
} from '@components';
import { Form } from 'antd';
import { closeSvg, searchSvg } from '@icons';
import { getGooglePlaceId } from '@utils/filter';

import "./deliveryPage.scss";

const DeliveryPage = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();

    const [quantity, setQuantity] = useState(1);
    const [googlePlaceId, setGooglePlaceId] = useState(null);
    const [postcode, setPostcode] = useState(null);
    const [apartment, setApartment] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [enableContinue, setEnableContinue] = useState(false);

    const addressComplete = useSelector(state => state.addressSlice.addressOptions);
    const addressFetchStatus = useSelector(state => state.addressSlice.status);
    const addressDetailStatus = useSelector(state => state.addressSlice.addressDetailStatus);
    const addressDetail = useSelector(state => state.addressSlice.addressDetail);

    // Upd [addressComplete]; autocomplete address
    useEffect(() => {

    }, [addressComplete]);

    // Upd [googlePlaceId]; get maps detail, when some address is select
    useEffect(() => {
        if (googlePlaceId) dispatch(addressDetailFetch(googlePlaceId));
    }, [googlePlaceId]);

    // Upd [addressDetail]; if we get addressDetail, set postcode
    useEffect(() => {
        if (addressDetailStatus === "success") {
            setPostcode(addressDetail.postcode);

            if (addressDetail.postcode) {
                form.setFields([{
                    name: 'address',
                    errors: null

                }])
            } else {
                form.setFields([{
                    name: 'address',
                    errors: ['Please, select address with postcode']
                }])
            }
        } else {
            setPostcode(null);
        }
    }, [addressDetail]);


    // Upd [quantity]; link quantity input and slider
    useEffect(() => {
        form.setFieldsValue({ quantity });
    }, [quantity]);

    // Upd [quantity]; link quantity input and slider
    useEffect(() => {

    }, [apartment]);

    // Upd [postcode, apartment, email, phone]; when form is fill, enable submit button
    useEffect(() => {
    }, [postcode, apartment, email, phone]);

    const onFinish = values => {

    }

    const addressSearchHandle = value => {
        dispatch(addressFetch(value));
    }

    const addressSelectHandle = value => {
        setGooglePlaceId(getGooglePlaceId(addressComplete, value))
    }

    const onClickClose = () => {
        history.push('/choose');
    }

    return <div className="delivery-wrapper">
        <DesktopOrTabletBreakpoint>
            <CloseButton onClick={onClickClose} />
        </DesktopOrTabletBreakpoint>

        <Title level={1} title="Order details" style={{ marginBottom: isMobile ? '24px' : '40px' }} />

        <div className="order-content-wrap">
            <div className="order-params-wrapper">
                <Form
                    form={form}
                    name="order-form"
                    onFinish={onFinish}
                    layout="vertical">

                    <div className="quantity-wrapper">
                        <Form.Item name="quantity" label={<Title level={4} title="Quantity of family members" style={{ marginBottom: '37px' }} />} style={{ width: '358px' }}>
                            <Slider
                                className="quantity-slider"
                                min={1}
                                max={20}
                                onChange={setQuantity}
                                value={quantity}
                            />
                        </Form.Item>

                        <InputNumber
                            className="quantity-input"
                            min={1}
                            max={20}
                            value={quantity}
                            onChange={setQuantity}
                            type="number"
                        />
                    </div>

                    <div className="address-wrap">
                        <Title level={4} title="Shipping Address" style={{ marginBottom: '8px' }} />

                        <Description>Delivery works on in UK mainland</Description>

                        <Form.Item name="address" rules={[{ required: true, message: 'Please select valid address' }]}>
                            <Autocomplete
                                dropdownMatchSelectWidth={450}
                                style={{
                                    width: 450,
                                }}
                                options={addressComplete}
                                onSearch={addressSearchHandle}
                                onSelect={addressSelectHandle}
                            >
                                <Input
                                    placeholder="Find Delivery Address"
                                    allowClear={true}
                                    hidePrefix={true}
                                    isFetching={addressFetchStatus === "loading"}
                                    prefix={<img height={24} width={24} src={searchSvg} />}
                                />
                            </Autocomplete>
                        </Form.Item>
                    </div>

                    <div className="contact-detail-wrap">
                        <Title level={4} title="Contact Details" />

                        <Form.Item name="apartment" rules={[{ required: true, message: 'Please enter valid apartment number' }]}>
                            <Input placeholder="Apartment Number" value={apartment} onChange={e => setApartment(e.target.value)} />
                        </Form.Item>

                        <Form.Item name="email" rules={[
                            { required: true, message: 'Please enter valid email' },
                            { type: 'email', message: 'The input is not valid E-mail' }
                        ]}>
                            <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Item>

                        <Form.Item name="phone" rules={[{ required: true, message: 'Please enter valid phone number' }]}>
                            <Input placeholder="Phone Number" value={phone} onChange={e => setPhone(e.target.value)} />
                        </Form.Item>
                    </div>
                </Form>
            </div>

            <div className="order-detail-wrapper">
                <Title level={4} title="Your test product" />

                <TestCard title="Lateral Flow Test" />
                <OrderSummary quantity={quantity} style={{ marginTop: '40px' }} />

                <DesktopOrTabletBreakpoint>
                    <div className="order-button-wrap" style={{ textAlign: "right" }}>
                        <Button type="primary" htmlType="submit" title="Continue Order" style={{ marginTop: '78px' }} />
                    </div>
                </DesktopOrTabletBreakpoint>
            </div>


        </div>
    </div>
}

export default DeliveryPage;
