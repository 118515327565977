import axios from 'axios';
import config from "@config";

const axiosInstance = axios.create({
    baseURL: config.lyfeApiActivationUrl
});

export const authPhoneVerification = (phone_number) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`/auth/phone_verification`, {phone_number})
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

// params: {verification_request_id, verification_code}
export const authSignUp = (params) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`/auth/sign_up`, params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

// params: {verification_request_id, verification_code}
export const authLogin = (params) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`/auth/login`, params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

// params: {verification_request_id, verification_code}
export const getPrivacyPolicy = () => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`/docs/privacy_policy`)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};