export const links = [{
    to: "/",
    title: "Main"
}, {
    to: "/choose",
    title: "Buy tests"
}, {
    to: "/calculator",
    title: "Calculator"
}, {
    to: "/dashboard",
    title: "Dashboard"
}]