import { useState } from 'react';
import { ModalLayout } from '@layouts';
import { Title, LabelText } from '@components';
import { helpSvg } from '@icons';
import InfoPopup from './infoPopup/InfoPopup';
import { testImg } from './../testImg';
import moment from 'moment';

import './testInfoCard.scss';


const TestInfoCard = ({ test_name, test_type, person, id, created_at, status, serial_number, activeCardId, setActiveCardId }) => {
    const [openModal, setOpenModal] = useState(false);
    const { gender, first_name: firstName, last_name: lastName, passport_number } = person;
    const { day, month, year } = person.date_of_birth;

    const momentCreatedAt = moment(created_at);
    const formatDate = momentCreatedAt.format('MMMM DD, YYYY');
    const formatDateShort = momentCreatedAt.format('MMMM DD');
    const formatTime = momentCreatedAt.format('hh:mm');
    const birthdayStr = `${('0' + day).slice(-2)}.${('0' + month).slice(-2)}.${year}`;
    const birthdayShortStr = `${('0' + day).slice(-2)}.${('0' + month).slice(-2)}.${year.slice(-2)}`;
    const fullName = `${firstName} ${lastName}`;
    const isActiveCard = activeCardId === id;

    return <div className={`test-info-wrap${isActiveCard ? ' active' : ''}`}>
        <div className="test-info-created-wrap">
            <div className="created-date">{formatDate}</div>
            <div className="created-time">{formatTime}</div>
        </div>

        <div className="test-card-wrap" key={`test-info-${id}`} onClick={() => setActiveCardId(id)}>
            <div className="info-logo-wrap">
                <img className="info-logo" src={testImg[test_type]} />
            </div>

            <div className="info-content-wrap">
                {false && <div className="info-status">{status}</div>}


                <Title title={
                    <div className="info-content-title-wrap">
                        {test_name} <img src={helpSvg} onClick={() => setOpenModal(true)} />
                    </div>
                } style={{ fontWeight: 700 }} />


                <div className="info-content-rowone">
                    <LabelText label="type" text={test_type} style={{ maxWidth: '162px' }} />
                    <LabelText label="id" text={serial_number} />
                </div>

                <div className="info-content-rowtwo">
                    <LabelText label="name" text={fullName} style={{ maxWidth: '162px' }} />
                    <LabelText label="sex" text={gender} />
                    <LabelText label="birthday" text={birthdayStr} />
                </div>
            </div>
        </div>

        <InfoPopup
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            serial_number={serial_number}
            birthdayShortStr={birthdayShortStr}
            test_type={test_type}
            formatDateShort={formatDateShort}
            formatTime={formatTime}
            fullName={fullName}
            gender={gender}
            passport_number={passport_number}
        />
    </div>
}

export default TestInfoCard;