import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { verificationFetch, signUpFetch, signInFetch, resetVerificationRequestId, resetSignUp, resetSignIn } from '@slice/authSlice';
import { BackButton, Title, Input, Button, Description, MobileBreakpoint } from "@components";
import { Form } from 'antd';
import { smsSvg } from '@icons';

import "./verification.scss";

const RESEND_TIMER_SECS = 120;

const Verification = ({ handleBack, isSignUp }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { signUpStatus, signInStatus, verificationStatus, verificationRequestId, accessToken, phone, signUpError, signInError, verificationError } = useSelector(state => state.authSlice);

    const [codeDigs, setCodeDigs] = useState({
        dig1: "",
        dig2: "",
        dig3: "",
        dig4: ""
    });
    const [timerPassSec, setTimerPassSec] = useState(RESEND_TIMER_SECS);
    const [isFillCode, setFillCode] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [isResetSms, setIsResetSms] = useState(false);

    // timer for resend SMS
    useEffect(() => {
        const timer = timerPassSec > 0 && setInterval(() => setTimerPassSec(timerPassSec - 1), 1000);

        return () => clearInterval(timer);
    }, [timerPassSec]);

    // check, if all inputs were filled
    useEffect(() => {
        const smsCode = `${codeDigs.dig1}${codeDigs.dig2}${codeDigs.dig3}${codeDigs.dig4}`;

        if (smsCode.length === 4) {
            setFillCode(true);
        } else {
            setFillCode(false);
        }
    }, [codeDigs]);

    // check, if reset sms was called
    useEffect(() => {
        if (isResetSms) {
            if (verificationStatus === "success") {
                setTimerPassSec(RESEND_TIMER_SECS);
                setIsResetSms(false);
                setErrMsg(null);
            } else if (verificationStatus === "fail") {
                setErrMsg(verificationError);
                setIsResetSms(false);
            }
        }
    }, [verificationStatus]);

    // check, signFetch request
    useEffect(() => {
        if (isSignUp) {
            if (signUpStatus === "success") {
                setErrMsg(null);
            } else if (signUpStatus === "fail") {
                setErrMsg(signUpError);
            }
        } else {
            if (signInStatus === "success") {
                setErrMsg(null);
            } else if (signInStatus === "fail") {
                setErrMsg(signInError);
            }
        }
    }, [signUpStatus, signInStatus]);

    // reset fetch status
    useEffect(() => {
        return () => {
            //    dispatch(resetSignUp());
            //    dispatch(resetSignIn());
            //   dispatch(resetVerificationRequestId());
        }
    }, [])

    const onFinish = () => {
        const params = {
            verification_request_id: verificationRequestId,
            verification_code: `${codeDigs.dig1}${codeDigs.dig2}${codeDigs.dig3}${codeDigs.dig4}`,
            phone
        };

        if (isSignUp) {
            dispatch(signUpFetch(params))
        } else {
            dispatch(signInFetch(params))
        }
    }

    // code inputs, only number, autoTab to next input
    const onChange = e => {
        const { value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        if (value !== "" && !Number(value) && value != 0) {
            return;
        }

        if (value.length === 1) {
            if (parseInt(fieldIndex, 10) < 4) {
                const nextSibling = document.querySelector(
                    `input[name=dig-${parseInt(fieldIndex, 10) + 1}]`
                );

                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }

        setCodeDigs({
            ...codeDigs,
            [`dig${fieldIndex}`]: value
        });
    }

    const resetCodeHandle = () => {
        dispatch(verificationFetch(phone));
        setIsResetSms(true);
    }

    const handleBackBtn = () => {
        dispatch(resetSignUp());
        dispatch(resetSignIn());
        dispatch(resetVerificationRequestId());
        handleBack();
    }

    return <div className="verification-wrap">
        <BackButton handleClick={handleBackBtn} />

        <MobileBreakpoint>
            <Title level={1} title="Lyfe" style={{ marginBottom: '60px', fontWeight: 500, textAlign: 'center' }} />
        </MobileBreakpoint>

        <Title level={1} title="Verification" style={{ marginBottom: isMobile ? '33px' : '81px' }} />

        <div className="verification-form-wrap">
            <Form
                form={form}
                name="verification-form"
                onFinish={onFinish}
                layout="vertical"
                className="lyfe-verification-form">

                <div className="otp-block-wrap">
                    <div className="otp-label-wrap">
                        <img src={smsSvg} />
                        <div className="otp-label">Check your incoming SMS with security key (4 digits) to confirm the sign up</div>
                    </div>

                    <div className={`otp-inputs-wrap${errMsg ? ' otp-error' : ''}`}>  {/* add otp-error*/}
                        <Input name="dig-1" placeholder="_" maxLength={1} onChange={onChange} value={codeDigs.dig1} inputMode="numeric" />
                        <Input name="dig-2" placeholder="_" maxLength={1} onChange={onChange} value={codeDigs.dig2} inputMode="numeric" />
                        <Input name="dig-3" placeholder="_" maxLength={1} onChange={onChange} value={codeDigs.dig3} inputMode="numeric" />
                        <Input name="dig-4" placeholder="_" maxLength={1} onChange={onChange} value={codeDigs.dig4} inputMode="numeric" />
                    </div>
                    <div className="otp-error-text">{errMsg}</div>

                    <div className="otp-resend-block-wrap">
                        {timerPassSec > 0 && <><Description>You may request new SMS in</Description>

                            <div className="otp-resend-timer-wrap">
                                <span style={{ fontWeight: 700 }}>{timerPassSec}</span>
                                <span> sec</span>
                            </div></>
                        }

                        <Button title="Resend SMS" style={{ width: '164px', height: '44px' }}
                            disabled={timerPassSec > 0} onClick={resetCodeHandle} loading={verificationStatus === "loading"}
                        />
                    </div>
                </div>

                <Button type="primary" htmlType="submit" title="Submit" style={{ width: '100%' }}
                    disabled={!isFillCode} loading={signUpStatus === "loading" || signInStatus === "loading"} />
            </Form>
        </div>
    </div>
}

export default Verification;