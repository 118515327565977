import { useMemo } from 'react';
import { Layout } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { DesktopOrTabletBreakpoint, MobileBreakpoint, Auth } from "@components";
import { links } from './links';

import "./header.scss";

const { Header: AntHeader } = Layout;

const Header = () => {
    const location = useLocation();
    const { pathname } = location;

    const linkItems = useMemo(() => {
        return links.map(({ to, title }) => <Link key={`link-${to}`} to={to} className={`link-item${to === pathname ? ' active' : ''}`}>{title}</Link>)
    }, [pathname, links])

    return <AntHeader className="header-wrapper">

        <DesktopOrTabletBreakpoint>
            <div className="logo">Lyfe</div>

            <div className="link-wrap">
                {linkItems}
            </div>
            <Link key={`link-test-activation`} to="/test-activation" className={`link-item-btn${'/test-activation' === pathname ? ' active' : ''}`}>Activate test kit</Link>

            <div className="profile">
                <div className="sign-up">
                    <Auth />
                </div>
            </div>
        </DesktopOrTabletBreakpoint>

        <MobileBreakpoint>
            <div className="menu">menu</div>
            <div className="logo">Lyfe</div>
            <div className="profile">
                <Auth />
            </div>
        </MobileBreakpoint>

    </AntHeader>
};

export default Header;
