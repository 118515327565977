import { useState, useMemo } from 'react';
import { DesktopOrTabletBreakpoint, MobileBreakpoint } from '@components';
import { PrivacyPolicy, SignUp, Verification } from './index';
import { ModalLayout } from '@layouts';
import { signUpSvg } from '@icons';

import "./auth.scss";

const Auth = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSignUp, setIsSignUp] = useState(true);
    const [isVerificationPage, setIsVerificationPage] = useState(false);
    const [isPrivacyPolicyPage, setIsPrivacyPolicyPage] = useState(false);

    const [isAgreePolicy, setIsAgreePolicy] = useState(false);

    // handle buttons on privacy policy page
    const handlePolicyAgreeBtn = () => {
        setIsAgreePolicy(true);
        setIsPrivacyPolicyPage(false);
        window.scrollTo(0, 0);
    }

    const handlePolicyCancelBtn = () => {
        setIsAgreePolicy(false);
        setIsPrivacyPolicyPage(false);
        window.scrollTo(0, 0);
    }
    // -----------------------------------

    const handlePrivacyPolicyLink = e => {
        e.preventDefault();
        setIsPrivacyPolicyPage(true);
    }

    let content = useMemo(() => {
        if (isVerificationPage) {
            return <Verification handleBack={() => setIsVerificationPage(false)} isSignUp={isSignUp} />;
        } else if (isPrivacyPolicyPage) {
            return <PrivacyPolicy handleAgree={handlePolicyAgreeBtn} handleCancel={handlePolicyCancelBtn} />
        } else {
            return <SignUp
                isSignUp={isSignUp}
                setIsSignUp={setIsSignUp}
                setIsVerificationPage={setIsVerificationPage}
                handlePrivacyPolicy={handlePrivacyPolicyLink}
                closeModal={() => setIsOpen(false)}
                isAgreePolicy={isAgreePolicy}
                setIsAgreePolicy={setIsAgreePolicy} />
        }
    }, [isSignUp, isVerificationPage, isPrivacyPolicyPage, isAgreePolicy])

    return <div className="auth-modal-wrap">
        <div className="auth-wrap" onClick={() => setIsOpen(true)}>
            <DesktopOrTabletBreakpoint>
                <div className="auth-header-label">Sign Up</div>
                <img className="auth-header-avatar" src={signUpSvg} />
            </DesktopOrTabletBreakpoint>

            <MobileBreakpoint>
                <img className="auth-header-avatar" height={32} width={32} src={signUpSvg} />
            </MobileBreakpoint>
        </div >

        <ModalLayout isOpen={isOpen} animateType="moveLeft" >
            {content}
        </ModalLayout>
    </div>
}

export default Auth;