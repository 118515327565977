import './labelText.scss';

const LabelText = ({ label, text, ...props }) => {
    return <div className="label-text-wrap" {...props}>
        <div className="content-label">{label}</div>
        <div className="content-text">{text}</div>
    </div>
};

export default LabelText;
