import CustomScroll from 'react-custom-scroll';
import { Description, Title } from '@components';
import { testImg } from './../testImg';

import './testCard.scss';

/**
 * 
 * size = small | medium | large
 */

const TestCard = ({ title, size = "large", short_description, price, category = "default" }) => {
    return (
        <div className={`lyfe-card-test-wrap ${size}`}>
            <div className="lyfe-card-logo">
                <img className={`test-logo ${size}`} src={testImg[category]} />
            </div>

            <div className="lyfe-card-content">
                <Title title={title} style={{ marginBottom: '14px', fontWeight: 700 }} />

                <CustomScroll>
                    <div className="card-desc-wrap">
                        <Description style={{ marginBottom: '8px' }}>{short_description}</Description>
                        <Description style={{ marginBottom: '8px' }}>Overseen by a professional health advisor bla bla bla</Description>
                        <Description style={{ marginBottom: '8px' }}>Some another desc about bla bla bla</Description>
                    </div>
                </CustomScroll>
            </div>
        </div>
    );
}

export default TestCard;