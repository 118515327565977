import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
    addressFetch, addressFetchSuccess, addressFetchFail, addressDetailFetch, addressDetailFetchSuccess, addressDetailFetchFail
} from '@slice/addressSlice';
import { getAddressAutocomplete, getAddressDetail } from '@utils/lyfe-api';
import { modifyAutocompleteOptions } from '@utils/filter';

function* addressSaga({ payload }) {
    try {
        if (!payload || payload.length < 2) {
            yield put(addressFetchSuccess({ options: [], input: null }));
            return;
        }

        yield delay(500); // debounce 0.5s

        const response = yield call(getAddressAutocomplete, payload);
        const { success, data } = response;

        window.Bugfender.log(`[/maps/autocomplete] input: ${payload}; response: ${JSON.stringify(response)}`);

        if (success) {
            yield put(addressFetchSuccess({ options: modifyAutocompleteOptions(data), input: payload }));
        } else {
            yield put(addressFetchFail());
        }

    } catch (exception) {
        yield put(addressFetchFail());
    }
}

function* addressDetailSaga({ payload }) {
    try {
        if (!payload) {
            yield put(addressDetailFetchSuccess({}));
            return;
        }

        const response = yield call(getAddressDetail, payload);
        const { success, data } = response;

        window.Bugfender.log(`[/maps/details?google_place_id=${payload}] google_place_id: ${payload}; response: ${JSON.stringify(response)}`);

        if (success) {
            yield put(addressDetailFetchSuccess(data));
        } else {
            yield put(addressDetailFetchFail());
        }

    } catch (exception) {
        yield put(addressDetailFetchFail());
    }
}

export default function* () {
    yield takeLatest(addressFetch, addressSaga);
    yield takeLatest(addressDetailFetch, addressDetailSaga);
}