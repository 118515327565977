import axios from 'axios';
import config from "@config";

const axiosInstance = axios.create({
    baseURL: config.lyfeApiUrl
});

export const searchTest = (params) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`/tests/search`, params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const choosedTestInfo = (id) => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`/tests/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

// params - deadline? = "2021-04-25T19:20:30.45+03:00"
export const getTimeline = ({ choosedTestId: id, deadline }) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`/tests/${id}/timeline`, { deadline })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const getAddressAutocomplete = (input) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(`/maps/autocomplete`, { input })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

export const getAddressDetail = (googlePlaceId) => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`/maps/details?google_place_id=${googlePlaceId}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};
