import { Switch, Route } from "react-router-dom";
import { Main, ChoosePage, HIWPage, LFTPage, DeliveryPage, DashboardPage } from '@pages';
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from './ScrollToTop';
import { MainLayout, MTCLayout } from "@layouts";

const Router = ({ auth }) => {
    return (
        <ScrollToTop>
            <Switch>
                <Route
                    path='/choose'
                    exact
                    render={(props) => (
                        <MainLayout>
                            <ChoosePage {...props} />
                        </MainLayout>
                    )} />

                <Route
                    path='/lft'
                    exact
                    render={(props) => (
                        <MainLayout contentHeight="100%">
                            <LFTPage {...props} />
                        </MainLayout>
                    )} />

                <Route
                    path='/hiw'
                    exact
                    render={(props) => (
                        <MainLayout contentHeight="100%">
                            <HIWPage {...props} />
                        </MainLayout>
                    )} />

                <Route
                    path='/delivery'
                    exact
                    render={(props) => (
                        <MainLayout contentHeight="100%">
                            <DeliveryPage {...props} />
                        </MainLayout>
                    )} />
                <PrivateRoute
                    path="/dashboard"
                    exact
                    auth={true}
                    render={(props) => (
                        <MainLayout>
                            <DashboardPage {...props} />
                        </MainLayout>
                    )} />
                <PrivateRoute path="/main" exact auth={auth} component={Main} />
                <Route
                    path='*'
                    exact
                    render={(props) => (
                        <MainLayout>
                            <ChoosePage {...props} />
                        </MainLayout>
                    )} />
            </Switch>
        </ScrollToTop>
    );
}

export default Router;