import { Bugfender } from '@bugfender/sdk';
import config from "@config";

Bugfender.init({
    appKey: 'iOghEvkFrisDZyZhUbJiuj0lg8x3XGx0',
    // apiURL: 'https://api.bugfender.com',
    // baseURL: 'https://dashboard.bugfender.com',
     overrideConsoleMethods: false,
    // printToConsole: true,
    // registerErrorHandler: true,
    // logBrowserEvents: true,
    // logUIEvents: true,
    //version: process.env.REACT_APP_VER,
    build: `[${config.env}] ${process.env.REACT_APP_VER}`,
});

window.Bugfender = Bugfender;
