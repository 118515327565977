import { useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './img.scss';

const Img = ({ src, showSpin = false, ...props }) => {
    const [removeSpin, setRemoveSpin] = useState(false);

    const imgLoad = () => {
        setRemoveSpin(true);
    }

    return <div style={{ position: 'relative' }}>
        {showSpin && !removeSpin && <div className="spin-wrap"><Spin size="large" indicator={<LoadingOutlined spin />} /></div>}
        <img src={src} onLoad={imgLoad} {...props} />
    </div>
}

export default Img;