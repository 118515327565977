import { forwardRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input as AntdInput, Spin } from 'antd';

import './input.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// hidePrefix - if true, hide prefix when value is not empty
const Input = forwardRef(({ value, prefix, hidePrefix, isFetching, allowClear, suffix, ...props }, ref) => {
    const inputSuffix = isFetching ? <Spin indicator={antIcon} /> : suffix;

    return <AntdInput
        prefix={hidePrefix && value ? false : prefix}
        ref={ref}
        value={value}
        allowClear={allowClear && !isFetching}
        suffix={inputSuffix}
        {...props}
        className="lyfe-input-wrap" />
});

export default Input;