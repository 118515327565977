import { call, put, takeLatest } from 'redux-saga/effects';
import {
    verificationFetch, verificationFetchSuccess, verificationFetchFail,
    signUpFetch, signUpFetchSuccess, signUpFetchFail,
    signInFetch, signInFetchSuccess, signInFetchFail,
    policyFetch, policyFetchSuccess, policyFetchFail
} from '@slice/authSlice';
import { authPhoneVerification, authSignUp, authLogin, getPrivacyPolicy } from '@utils/lyfe-api-activation';

function* verificationSaga({ payload }) {
    try {
        const response = yield call(authPhoneVerification, payload);
        const { success, data, message } = response;

        window.Bugfender.log(`[auth/phone_verification] phone: **${payload.slice(-4)}; response: ${JSON.stringify(response)}`);

        if (success) {
            yield put(verificationFetchSuccess({ verificationRequestId: data.verification_request_id, phone: payload }));
        } else {
            yield put(verificationFetchFail(message));
        }
    } catch (exception) {
        yield put(verificationFetchFail());
    }
}

function* signUpSaga({ payload }) {
    try {
        const response = yield call(authSignUp, payload);
        const { success, data, message } = response;

        window.Bugfender.log(`[auth/sign_up] phone: **${payload.phone.slice(-4)}; response: ${JSON.stringify(response)}`);
        
        if (success) {
            yield put(signUpFetchSuccess(data.access_token));
        } else {
            yield put(signUpFetchFail(message));
        }
    } catch (exception) {
        yield put(signUpFetchFail());
    }
}

function* signInSaga({ payload }) {
    try {
        const response = yield call(authLogin, payload);
        const { success, data, message } = response;

        window.Bugfender.log(`[auth/login] phone: **${payload.phone.slice(-4)}; response: ${JSON.stringify(response)}`);

        if (success) {
            yield put(signInFetchSuccess(data.access_token));
        } else {
            yield put(signInFetchFail(message));
        }
    } catch (exception) {
        yield put(signInFetchFail());
    }
}

function* policySaga() {
    try {
        const response = yield call(getPrivacyPolicy);
        const { success, data, message } = response;

        if (success) {
            yield put(policyFetchSuccess(data.privacy_policy));
        } else {
            yield put(policyFetchFail(message));
        }
    } catch (exception) {
        yield put(policyFetchFail());
    }
}

export default function* () {
    yield takeLatest(verificationFetch, verificationSaga);
    yield takeLatest(signUpFetch, signUpSaga);
    yield takeLatest(signInFetch, signInSaga);
    yield takeLatest(policyFetch, policySaga);
}