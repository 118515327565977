import { useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import { MTCLayout } from '@layouts';
import { TestInfoCard, TabsMenu, Title, Button } from '@components';
import { helpSvg } from '@icons';

import './dashboardPage.scss';

const DashboardPage = () => {
    const [activeCardId, setActiveCardId] = useState("tab-activate");
    const [activeTab, setActiveTab] = useState();

    return <MTCLayout
        colOne={
            <>
                <TabsMenu activeTab={activeTab} setActiveTab={setActiveTab} style={{ marginBottom: '24px' }} />
                <CustomScroll allowOuterScroll={true}>
                    <div className="dashboard-content-wrap">
                        <TestInfoCard
                            key="608839163335441b81867821"
                            id="608839163335441b81867821"
                            activeCardId={activeCardId}
                            setActiveCardId={setActiveCardId}
                            serial_number="123456789"
                            test_type="antigen"
                            test_name="Lateral Flow Test"
                            created_at="2021-04-27T16:16:10.223Z"
                            person={{
                                first_name: 'John',
                                last_name: 'Smith',
                                gender: 'male',
                                passport_number: '123456789',
                                date_of_birth: {
                                    "day": "1",
                                    "month": "1",
                                    "year": "1990"
                                }
                            }}
                            status="failed"
                        />

                        <TestInfoCard
                            key="608839163335441b81867822"
                            id="608839163335441b81867822"
                            activeCardId={activeCardId}
                            setActiveCardId={setActiveCardId}
                            serial_number="123456789"
                            test_name="Lateral Flow Test"
                            test_type="switchHealthTest"
                            created_at="2021-04-27T16:16:10.223Z"
                            person={{
                                first_name: 'Alan',
                                last_name: 'Smith',
                                gender: 'male',
                                passport_number: '123456789',
                                date_of_birth: {
                                    "day": "1",
                                    "month": "3",
                                    "year": "1993"
                                }
                            }}
                            status="passed"
                        />

                        <TestInfoCard
                            key="608839163335441b81867823"
                            id="608839163335441b81867823"
                            activeCardId={activeCardId}
                            setActiveCardId={setActiveCardId}
                            serial_number="123456789"
                            test_type="pcrBlueTest"
                            test_name="Lateral Flow Test"
                            created_at="2021-04-27T16:16:10.223Z"
                            person={{
                                first_name: 'Auron Auron Auron Auron Auron',
                                last_name: 'Smith',
                                gender: 'female',
                                passport_number: '123456789',
                                date_of_birth: {
                                    "day": "11",
                                    "month": "5",
                                    "year": "1995"
                                }
                            }}
                            status="passed"
                        />

                        <TestInfoCard
                            key="608839163335441b81867824"
                            id="608839163335441b81867824"
                            activeCardId={activeCardId}
                            setActiveCardId={setActiveCardId}
                            serial_number="123456789"
                            test_type="pcrBlueTest"
                            test_name="Lateral Flow Test"
                            created_at="2021-04-27T16:16:10.223Z"
                            person={{
                                first_name: 'Auron Auron Auron Auron Auron',
                                last_name: 'Smith',
                                gender: 'female',
                                passport_number: '123456789',
                                date_of_birth: {
                                    "day": "11",
                                    "month": "5",
                                    "year": "1995"
                                }
                            }}
                            status="passed"
                        />
                    </div>
                </CustomScroll>
            </>
        }

        colTwo={
            <div className="dashboard-detail-wrap">
                <Title level={4} title="Your next step" style={{ marginBottom: '32px', fontWeight: 700 }} />

                <Title title={
                    <div className="detail-call-title-wrap">
                        <span>Call with Remote Supervisor</span><img src={helpSvg} onClick={() => console.log('show call modal')} />
                    </div>
                } style={{ fontWeight: 700, marginBottom: '12px' }} />

                <div className="detail-msg">You should undergo remote supervision within 3 days after test has been activated !</div>

                <div className="detail-schedule-wrap">
                    <div className="schedule-item-wrap"><span>Mon</span><span>08:20 am - 5:45 pm</span></div>
                    <div className="schedule-item-wrap"><span>Tue</span><span>08:20 am - 5:45 pm</span></div>
                    <div className="schedule-item-wrap"><span>Wed</span><span>08:20 am - 5:45 pm</span></div>
                    <div className="schedule-item-wrap"><span>Thu</span><span>08:20 am - 5:45 pm</span></div>
                    <div className="schedule-item-wrap"><span>Fri</span><span>08:20 am - 5:45 pm</span></div>
                </div>

                <Button type="primary" style={{ width: '140px', height: '48px', marginTop: '24px' }} title="Go to Call" />
            </div>
        }
    />

}

export default DashboardPage;