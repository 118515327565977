import searchSlice from "./searchSlice";
import chooseSlice from "./chooseSlice";
import redirectSlice from "./redirectSlice";
import timelineSlice from "./timelineSlice";
import addressSlice from "./addressSlice";
import authSlice from "./authSlice";

export const reducers = {
    searchSlice,
    chooseSlice,
    redirectSlice,
    timelineSlice,
    addressSlice,
    authSlice
};