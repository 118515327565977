import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setRedirectInfo, resetRedirectInfo } from "@slice/redirectSlice";
import { Title, BackButton, Button, DesktopOrTabletBreakpoint, MobileBreakpoint, TimelineInfo, FixedButton } from '@components';

import './hiwPage.scss';

const HIWPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const choosedTestId = useSelector(state => state.searchSlice.choosedTestId);
    const events = useSelector(state => state.timelineSlice.events);
    const redirectInfo = useSelector(state => state.redirectSlice);

    // redirect to 'choose' page, if data not loaded
    useEffect(() => {
        if (choosedTestId === null) history.push('/choose');
    }, [choosedTestId]);

    // Upd [redirectInfo, timelineFetching]; redirect when data is load
    useEffect(() => {
        if (redirectInfo.needRedirect) history.push(redirectInfo.pathTo);
    }, [redirectInfo]);

    // Unmount
    useEffect(() => {
        return () => dispatch(resetRedirectInfo());
    }, []);

    const handleContinue = () => {
        dispatch(setRedirectInfo({ needRedirect: true, pathTo: "/delivery" })); // when data will be load, redirect to 'delivery' page
    };

    const handleClose = () => {
        history.push("/lft");
    }

    return (
        <div className="hiw-wrapper">
            <DesktopOrTabletBreakpoint>
                <BackButton path='/lft' />
            </DesktopOrTabletBreakpoint>

            <Title level={1} title="How it works" style={{ marginBottom: '12px' }} />
            <div className="hiw-description">Something about this page</div>

            <div className="hiw-content-wrapper">
                <TimelineInfo events={events} />
            </div>

            <div className="hiw-button-wrapper">
                <DesktopOrTabletBreakpoint>
                    <Button type="primary" htmlType="submit" title="continue" style={{ marginTop: '20px' }} onClick={handleContinue} />
                </DesktopOrTabletBreakpoint>

                <MobileBreakpoint>
                    <FixedButton>
                        <Button type="primary" htmlType="submit" title="continue" onClick={handleContinue} />
                        <Button title="close" style={{ marginTop: '24px' }} onClick={handleClose} />
                    </FixedButton>
                </MobileBreakpoint>
            </div>

        </div>
    )
}

export default HIWPage;

