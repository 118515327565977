import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { verificationFetch, setPhone } from '@slice/authSlice';
import { CloseButton, Title, Input, Checkbox, Button, MobileBreakpoint } from '@components';
import { Form } from 'antd';
import { phoneSvg } from '@icons';

import './signUp.scss';

// SignUp and SignIn block
const SignUp = ({ isSignUp, closeModal, handlePrivacyPolicy, setIsVerificationPage, setIsSignUp, isAgreePolicy, setIsAgreePolicy }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { verificationStatus, verificationRequestId, verificationError, phone } = useSelector(state => state.authSlice);

    // set init value from store
    useEffect(() => {
        phone && form.setFieldsValue({ phone });
    }, []);

    useEffect(() => {
        form.setFieldsValue({ isAgree: isAgreePolicy });
    }, [isAgreePolicy]);

    // check verificationFetch
    useEffect(() => {
        if (verificationStatus === "success") {
            verificationRequestId && setIsVerificationPage(true); // open verification page
        } else if (verificationStatus === "fail") {
            form.setFields([{
                name: 'phone',
                errors: [verificationError]
            }])
        }
    }, [verificationStatus]);

    const onFinish = values => {
        dispatch(verificationFetch(values.phone));
    }

    const handlePrivacyPolicyLink = (e) => {
        const tmpPhone = form.getFieldValue("phone");

        tmpPhone && dispatch(setPhone(tmpPhone));
        handlePrivacyPolicy(e)
    };

    return <>
        <CloseButton onClick={closeModal} />

        <div className="sign-up-content-wrap">
            <MobileBreakpoint>
                <Title level={1} title="Lyfe" style={{ marginBottom: '60px', fontWeight: 500, textAlign: 'center' }} />
            </MobileBreakpoint>

            <div className="sign-up-title-wrap">
                <Title level={1} title="Sign" style={{ marginRight: '8px', flex: 1 }} />
                <Title level={1} title={isSignUp ? 'Up' : 'In'} style={{ color: '#1C0F3740' }} />
            </div>

            <div className="sign-up-form-wrap">
                <Form
                    form={form}
                    name="sign-in-form"
                    onFinish={onFinish}
                    layout="vertical"
                    className="lyfe-sign-form">

                    <div>
                        <div className="phone-label"><img src={phoneSvg} />Please enter your phone number</div>
                        <Form.Item name="phone" rules={[{ required: true, message: 'Please enter valid phone number' }]}>
                            <Input prefix="+ 44" inputMode="tel" />
                        </Form.Item>
                    </div>

                    <div>
                        {isSignUp &&
                            <Form.Item name="isAgree" valuePropName="checked" style={{ height: 'auto', marginBottom: '32px' }}>
                                <Checkbox onChange={(e) => setIsAgreePolicy(e.target.checked)}>I have read and agree with the <span className="sign-button" onClick={handlePrivacyPolicyLink}>Privacy Policy</span></Checkbox>
                            </Form.Item>
                        }

                        <Button type="primary" htmlType="submit" title={isSignUp ? 'Create Account' : 'Sign In'} style={{ width: '100%' }}
                            disabled={isSignUp && !isAgreePolicy} loading={verificationStatus === "loading"} />

                        <div className="sign-footer-text">
                            {isSignUp && <>
                                <span>Already have an account? </span>
                                <span className="sign-button" onClick={() => setIsSignUp(false)}>Sign In</span>
                            </>}

                            {!isSignUp && <>
                                <span>Don't have an account? </span>
                                <span className="sign-button" onClick={() => setIsSignUp(true)}>Sign Up</span>
                            </>}
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    </>
}

export default SignUp;