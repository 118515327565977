import { createSlice } from '@reduxjs/toolkit';

/**
 *  status: 'idle' | 'loading' | 'success' | 'fail'
 *  error: string | null
 */
export const initialState = {
    verificationStatus: "idle",
    verificationRequestId: null,
    verificationError: null,

    signUpStatus: "idle",
    signUpError: null,

    signInStatus: "idle",
    signInError: null,

    policyStatus: "idle",
    policyContent: null,
    policyError: null,

    accessToken: null,
    phone: null,
};

const defaultSignErrMsg = 'Security key is not valid. Please try again.';
const defaultVerificationErrMsg = 'Phone number is incorrect.';

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        verificationFetch: state => {
            state.verificationStatus = "loading";
        },
        verificationFetchSuccess: (state, { payload }) => {
            state.verificationStatus = "success";
            state.verificationRequestId = payload.verificationRequestId;
            state.phone = payload.phone;
        },
        resetVerificationRequestId: state => {
            state.verificationRequestId = null;
        },
        verificationFetchFail: (state, { payload }) => {
            state.verificationStatus = "fail";
            state.verificationError = payload || defaultVerificationErrMsg;
        },
        signUpFetch: state => {
            state.signUpStatus = "loading";
        },
        signUpFetchSuccess: (state, { payload }) => {
            state.signUpStatus = "success";
            state.accessToken = payload;
        },
        resetSignUp: state => {
            state.signUpStatus = "idle";
            state.accessToken = null;
        },
        signUpFetchFail: (state, { payload }) => {
            state.signUpStatus = "fail";
            state.accessToken = null;
            state.signUpError = payload || defaultSignErrMsg;
        },
        signInFetch: state => {
            state.signInStatus = "loading";
        },
        signInFetchSuccess: (state, { payload }) => {
            state.signInStatus = "success";
            state.accessToken = payload;
        },
        resetSignIn: state => {
            state.signInStatus = "idle";
            state.accessToken = null;
        },
        signInFetchFail: (state, { payload }) => {
            state.signInStatus = "fail";
            state.accessToken = null;
            state.signInError = payload || defaultSignErrMsg;
        },
        setPhone: (state, { payload }) => {
            state.phone = payload;
        },
        policyFetch: state => {
            state.policyStatus = "loading";
        },
        policyFetchSuccess: (state, { payload }) => {
            state.policyStatus = "success";
            state.policyContent = payload;
        },
        policyFetchFail: (state, { payload }) => {
            state.policyStatus = "fail";
            state.policyContent = null;
            state.policyError = payload;
        }
    }
});

export const {
    verificationFetch, verificationFetchSuccess, resetVerificationRequestId, verificationFetchFail,
    signUpFetch, signUpFetchSuccess, resetSignUp, signUpFetchFail,
    signInFetch, signInFetchSuccess, resetSignIn, signInFetchFail,
    setPhone,
    policyFetch, policyFetchSuccess, policyFetchFail
} = authSlice.actions;
export default authSlice.reducer;