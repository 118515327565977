import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { policyFetch } from '@slice/authSlice';
import { Button, Title, MobileBreakpoint } from "@components";
import { Spin } from 'antd';

import "./privacyPolicy.scss";

const PrivacyPolicy = ({ handleAgree, handleCancel }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const dispatch = useDispatch();

    const policyStatus = useSelector(state => state.authSlice.policyStatus);
    const policyContent = useSelector(state => state.authSlice.policyContent);

    const content = useMemo(() => {
        if (policyStatus === "success") {
            return policyContent;
        } else {
            return <Spin />
        }
    }, [policyStatus]);

    useEffect(() => {
        dispatch(policyFetch());
    }, []);

    return <div className="privacy-wrap">
        <MobileBreakpoint>
            <Title level={1} title="Lyfe" style={{ marginBottom: '60px', fontWeight: 500, textAlign: 'center' }} />
        </MobileBreakpoint>

        <Title level={1} title="Privacy Policy" style={{ marginBottom: isMobile ? '20px' : '32px' }} />

        <div className="privacy-content">
            {content}
        </div>

        <div className="privacy-button-wrap">
            <Button type="primary" title="I Agree" style={{ width: '100%' }} onClick={handleAgree} disabled={policyStatus !== "success"} />
            <Button title="Cancel" style={{ width: '100%', marginTop: '14px' }} onClick={handleCancel} />
        </div>
    </div >
}

export default PrivacyPolicy;