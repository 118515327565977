import { useMemo } from 'react';
import './tabsMenu.scss';

const tabs = [{ name: 'tab-activate', title: 'activated' }, { name: 'tab-supervised', title: 'supervised' }, { name: 'test-results', title: 'test results' }]

const TabsMenu = ({ activeTab, setActiveTab, ...props }) => {
    const tabMenus = useMemo(() => {
        return tabs.map(({ name, title }) => <div className={`tab-item${activeTab === name ? ' active' : ''}`} onClick={() => { setActiveTab(name) }}>{title}</div>)
    }, [tabs, activeTab]);

    return <div className="tabs-menu-wrap" {...props}>
        {tabMenus}
    </div>

}

export default TabsMenu;